import React, {useEffect} from 'react';
import Desktop from './desktop/desktop';
import Mobile from './mobile/mobile';
const Home =()=> {
  useEffect(()=>{
    document.getElementById('anchor').scrollIntoView();
  },[])
  if(window.innerWidth>=window.innerHeight){
    return <Desktop />
  }else{
    return <Mobile />
  }
}

export default Home;