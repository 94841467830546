import React from 'react';

const pointServices_desk = props => {

  return (
    <div className='row relative' style={{width:'65vw',padding:'3vw 0',flexWrap:'wrap'}}>
        {   
            props.serviceNames.map(service=>{
               return(
               <div key={service} className='row' style={{alignItems:'baseline',width:'20vw',height:'2vw'}}>
                        <div style={{width:'1vw',height:'1vw',background:'#ff2c02',transform:'skewX(-20deg)'}}></div>
                        <p style={{width:'20vw',textAlign:'left',color:'#d9d9d9',fontSize:'1.4vw',margin:'1vw 0 1vw 1vw'}}>{service}</p>
                    </div>
               );
            })
            
        }
    </div>
  )
}

export default pointServices_desk;