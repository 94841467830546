import React from 'react';


const deskription = props => {
  return (
    <div className='row relative' style={{width:'90vw',marginTop:'3vw',justifyContent:'center'}}>
      <div className='row'>
        <div style={{width:'3vw',height:props.parHeight,transform:'skew(-20deg)',background:'#ff2c02'}}></div>
        <div style={{margin:'0 1vw',width:'1vw',height:props.parHeight,transform:'skew(-20deg)',background:'#ff2c02',opacity:'0.7'}}></div>
        <div style={{width:'0.5vw',height:props.parHeight,transform:'skew(-20deg)',background:'#ff2c02',opacity:'0.5'}}></div>
      </div>
        <p style={{width:props.w,color:'#d9d9d9',textAlign:props.align,fontSize:'1.8vw',margin:'3vw',lineHeight:'3vw'}}><span style={{color:'#ff2c02',fontWeight:'700'}}>{props.title}</span>{props.text}</p>
      <div className='row'>
        <div style={{width:'0.5vw',height:props.parHeight,transform:'skew(-20deg)',background:'#d9d9d9',opacity:'0.5'}}></div>
        <div style={{margin:'0 1vw',width:'1vw',height:props.parHeight,transform:'skew(-20deg)',background:'#d9d9d9',opacity:'0.7'}}></div>
        <div style={{width:'3vw',height:props.parHeight,transform:'skew(-20deg)',background:'#d9d9d9'}}></div>
      </div>
    </div>
  )
}

export default deskription;