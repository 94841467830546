import React from 'react';
import Header from '../../functional/deskHeader-mobile';
import Services from '../../functional/pointServices_mob';

import Description from '../../functional/deskription-mobile-section';
import Whats from '../../functional/customWhats'
const Mobile =()=> {
    
    
    return (
      <div className='relative column' style={{overflowY:'scroll',overflowX:'hidden'}}>
        <Header text={'VENDITA SUPERCARS'}/>
         <video style={{width:'100vw',height:'100vw',objectFit:'cover',margin:'0'}}
               crossOrigin="*"
               autoPlay
               muted
               loop>
                <source src='https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/supercars_ferrari_mobile.mp4' type="video/mp4" />

        </video>
        <Description 
             margin={'2vw 0'}
             w={'80vw'}
             align={'justify'}
             title={null}
             text={`Assistenza specializzata a 360° pre e post-vendita.`}
             text1={null}
             />
             <Services serviceNames={[
              'finanziamenti e leasing',
              'garanzia di conformità',
              'permuta usato',
              'assistenza pre post vendita',
              'consegne auto',
              'conto vendita']}  />

        <Whats svgStyle={{width:'15vw',fill:'#25d366',margin:'0 1vw 0 2.5vw'}} 
             text={'RICHIEDI INFORMAZIONI'}
             pStyle={{fontSize:'5vw',fontWeight:'700',margin:'0 2.5vw 0 0'}}
             parHeight={'5vw'}
             mainMargin={'0 0 10vw 0'} />

        <div style={{width:'100vw',height:'150vh'}}>
          <iframe src="https://www.autoscout24.it/concessionari/embedded-list/rs-motors-srl-roma?preview=false" frameBorder="0" width="100%" height="100%">Il tuo browser non supporta gli iframe.</iframe>

        </div>
              
      </div>
      
    )
  }

export default Mobile;