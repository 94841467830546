import React from 'react';
import Desktop from './desktop/desktop';
import Mobile from './mobile/mobile';
const Carousel =()=> {
  if(window.innerWidth>=window.innerHeight){
    return <Desktop />
  }else{
    return <Mobile />
  }
}

export default Carousel;