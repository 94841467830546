import React from 'react';
import Header from '../../functional/deskHeader-mobile';

import Description from '../../functional/deskription-mobile-section';

import FullTripleImg from '../../functional/fullImage-mobile';
import driving from '../../../assets/drivingSimulationCenter.svg';
import s2 from '../../../assets/s2.jpg';
import Whats from '../../functional/customWhats';
const Mobile =()=> {
    
    
    return (
      <div className='relative column' style={{overflowX:'hidden',width:'100vw'}}>
         <Header text={'DRIVING SIMULATOR'}/>
         <video style={{width:'100vw',height:'100vw',objectFit:'cover',margin:'0'}}
               crossOrigin="*"
               autoPlay
               muted
               loop>
                <source src='https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/simulatore720px.mp4' type="video/mp4" />

        </video>

        <Description 
             margin={'2vw 0'}
             w={'80vw'}
             align={'justify'}
             title={'Impugnare il volante della Ferrari di Formula 1 è un sogno impossibile?'}
             text={`Con il nostro T7 Pro Simulatore Dinamico sarà quasi diventato Realtà!`}
             text1={`RSimulation è un servizio dedicato agli appassionati della guida virtuale, a chi sogna l’automobilismo da competizione, ai piloti che vogliono allenarsi in modo innovativo, ed a chi vuole semplicemente divertirsi e provare l' emozione di guidare come fosse un vero pilota!`}
             />
        <Whats svgStyle={{width:'15vw',fill:'#25d366',margin:'0 1vw 0 2.5vw'}} 
             text={'RICHIEDI INFORMAZIONI'}
             pStyle={{fontSize:'5vw',fontWeight:'700',margin:'0 2.5vw 0 0'}}
             parHeight={'5vw'}
             mainMargin={'0 0 1vw 0'} />
        <FullTripleImg 
        imageUrl={'https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/volanteSimulatore.jpg'} 
        imgStyle={{objectFit:'cover',height:'110vw',transform:'skewY(20deg) translateY(-5vw)'}}
        image2Url={s2} 
        img2Style={{objectFit:'cover',height:'100vw',transform:'skewY(20deg) translate(10vw,0)'}}
        />
        <div className='column' style={{width:'90vw'}}>
                <p style={{fontSize:'5vw',margin:'10vw 0',textAlign:'center',color:'#d9d9d9',lineHeight:'5vw'}}>
                    Vieni a trovarci presso il Driving Simulation Center di Vallelunga<br/>
                    Dal Martedi al Venerdi / 19:00 - 23:30 <br/>
                    Sabato e Domenica / 10:00 - 23:00
                </p>
                <img src={driving} style={{width:'50vw'}} />
        </div>
      </div>
      
    )
  }

export default Mobile;