import React from 'react';

export const button = props =>{
    return(
        <div className='row relative' style={props.wrapStyle}
             onMouseEnter={props.enter} 
             onMouseLeave={props.leave}>
                <p className='base-button absolute' style={{
                       transform:'skew(-20deg)',
                       transition:'400ms',
                       margin:'0',
                       padding:'0.5vw 1vw',
                       cursor:'pointer',
                       fontSize:'1vw',
                       fontWeight:'800'
                    }}>
                
                    {props.text}
                </p>
        </div>
    );
}

export const mobButton = props =>{
    return(
        <div className='row relative' style={props.wrapStyle}
             onMouseEnter={props.enter} 
             onMouseLeave={props.leave}>
                <p className='absolute' style={{
                       background:'#ff2c02cc', 
                       transform:'skew(-20deg)',
                       margin:'0',
                       height:'5vw',
                       fontSize:'4vw',
                       padding:'10px 20px',
                       color:'#d9d9d9',
                    }}>
                
                    {props.text}
                </p>
        </div>
    );
}