import React from 'react';
import Desktop from './desktop';
import Mobile from './mobile';
const Footer =()=> {
 
  if(window.innerWidth>=window.innerHeight){
    return <Desktop />
  }else{
    return <Mobile />
  }
}

export default Footer;
