import React from 'react';
import Carousel from '../../carousel/carousel';
import Description from '../../functional/deskription-mobile';
import FullDoubleImage from '../../functional/fullImage-mobile';
import one from '../../../assets/about_1.jpg';
import two from '../../../assets/about_2.jpg';
import DSC from '../../svg/DSC';
import Scout from '../../svg/autoScout';
const Mobile =()=> {
    
    return (
        <div className='relative column' style={{width:'100vw',color:'#d9d9d9',overflowX:'hidden'}}>
     
        <Carousel />
        <Description 
             w={'85vw'}
             align={'justify'}
             title={'RS '}
             text={`MOTORS nasce dalla passione per il mondo delle quattro ruote e un’ esperienza decennale nel settore.
             "Non solo auto ma tanto altro."`}
             />
             <FullDoubleImage 
          imageUrl={one} 
          imgStyle={{width:'160vw',transform:'skewY(20deg) translate(0) rotate(-5deg)'}}
          image2Url={two} 
          img2Style={{width:'160vw',transform:'skewY(20deg) translateX(0)'}}
          
      />
      <div className='column'>
        <p style={{fontSize:'3vw',fontWeight:'300'}}>IN COLLABORAZIONE CON:</p>
        <div className='row'>
          <div className='row' style={{border:'1px solid #d9d9d9',margin:'0 3vw 0 0',transform:'skewX(-20deg)',padding:'3vw'}}>
            <DSC svgStyle={{height:'15vw',transform:'skewX(20deg)'}} />
          </div>
          <div className='row' style={{border:'1px solid #d9d9d9',transform:'skewX(-20deg)',padding:'3vw 5vw'}}>
          <Scout svgStyle={{height:'15vw',transform:'skewX(20deg)'}} />
          </div>
        </div>
      </div>
        
    
       
    </div>
      
    )
  }

export default Mobile;