import React from 'react'

const fullDoubleImage =({imageUrl,image2Url,imgStyle,img2Style,image3Url,img3Style,alText,alText1,alText2})=> {
  return (
    <div className='row' style={{height:'25vw',margin:'5vw 0'}}>
      <div className='row' style={{width:'40vw',height:'25vw',transform:'skewX(-20deg)',overflow:'hidden',margin:'0'}}>
        <img src={imageUrl} style={imgStyle} alt={alText} />
      </div>
      <div className='row' style={{width:'30vw',height:'25vw',transform:'skewX(-20deg)',overflow:'hidden',margin:'0 2vw'}}>
        <img src={image2Url} style={img2Style} alt={alText1} />
      </div>

      <div className='row' style={{width:'40vw',height:'25vw',transform:'skewX(-20deg)',overflow:'hidden',margin:'0'}}>
        <img src={image3Url} style={img3Style} alt={alText2} />
      </div>
    </div>
  )
}

export default fullDoubleImage;