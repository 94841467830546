import React, { useState, useEffect } from 'react'
import Header from '../../functional/deskHeader-mobile';

import { phone as Phone, home as Home, mail as Mail } from '../../functional/icons';

const Mobile =()=> {
  
    
    return (
      <div className='relative column' style={{overflow:'hidden',width:'100vw',height:'100vh',justifyContent:'flex-start',color:'#d9d9d9'}}>
           <Header text={'CONTATTI'}/>

            
            <div className='column' style={{alignItems:'flex-start',height:'70vh'}}>
              <div className='row'>
                <div className='row' style={{margin:'0 5vw 0 0'}}>
                  <div style={{width:'3vw',height:'8vw',transform:'skew(-20deg)',background:'#d9d9d9'}}></div>
                  <div style={{margin:'0 1vw',width:'1vw',height:'8vw',transform:'skew(-20deg)',background:'#d9d9d9',opacity:'0.7'}}></div>
                  <div style={{width:'0.5vw',height:'8vw',transform:'skew(-20deg)',background:'#d9d9d9',opacity:'0.5'}}></div>
                </div>
                <Home svgStyle={{width:'8vw',fill:'#ff2c02',margin:'0 2vw 0 0'}} />
                <div className='column' style={{alignItems:'flex-start'}}>
                  <p style={{fontSize:'5vw',margin:'0'}}>Piazza di Pietra 26, 00187 Roma</p>
                  <p style={{fontSize:'4vw',margin:'0'}}>Lun-Ven  9:00-17:00</p>

                </div>
              </div>
        
 
              <div className='row' style={{margin:'10vw 0'}}>
                <div className='row' style={{margin:'0 5vw 0 0'}}>
                  <div style={{width:'3vw',height:'8vw',transform:'skew(-20deg)',background:'#d9d9d9'}}></div>
                  <div style={{margin:'0 1vw',width:'1vw',height:'8vw',transform:'skew(-20deg)',background:'#d9d9d9',opacity:'0.7'}}></div>
                  <div style={{width:'0.5vw',height:'8vw',transform:'skew(-20deg)',background:'#d9d9d9',opacity:'0.5'}}></div>
                </div>
                <Phone svgStyle={{width:'8vw',fill:'#ff2c02',margin:'0 2vw 0 0'}} />
                <p style={{fontSize:'5vw',margin:'0'}}>+39 351 162 2506</p>
              </div>
    
              <a href='mailto:info@rs-motors.eu' style={{textDecoration:'none',color:'#d9d9d9'}}>
              <div className='row' style={{}}>
                <div className='row' style={{margin:'0 5vw 0 0'}}>
                  <div style={{width:'3vw',height:'8vw',transform:'skew(-20deg)',background:'#d9d9d9'}}></div>
                  <div style={{margin:'0 1vw',width:'1vw',height:'8vw',transform:'skew(-20deg)',background:'#d9d9d9',opacity:'0.7'}}></div>
                  <div style={{width:'0.5vw',height:'8vw',transform:'skew(-20deg)',background:'#d9d9d9',opacity:'0.5'}}></div>
                </div>
                <Mail svgStyle={{width:'8vw',fill:'#ff2c02',margin:'0 2vw 0 0'}} />
                  <p style={{fontSize:'5vw',margin:'0'}}>info@rs-motors.eu</p>
                </div>
              </a>
          </div>
              
              
        </div>

    );
  }

export default Mobile;