import React from 'react';
import Header from '../../functional/deskHeader';
import { phone as Phone, home as Home, mail as Mail } from '../../functional/icons';
const Desktop =()=> {
  
  
  return (
    
        <div className='relative column' style={{width:'100vw',color:'#d9d9d9',overflow:'hidden'}}>
            
            <Header text={' CONTATTI'} />
            <div className='column' style={{alignItems:'flex-start',padding:'5vw',borderTop:'1px solid #ff2c02',borderRight:'1px solid #ff2c02',margin:'5vw',transform:'skew(-20deg)'}}>
                <div className='row' style={{transform:'skew(20deg)',margin:'1vw'}}>
                    <Home svgStyle={{width:'1.5vw',fill:'#ff2c02',margin:'0 2vw 0 0'}} />
                    <div className='column' style={{alignItems:'flex-start'}}>
                        <p style={{fontSize:'1.4vw',margin:'0'}}>Piazza di Pietra 26, 00187 Roma</p>
                        <p style={{fontSize:'1.2vw',margin:'0'}}>Lun-Ven  9:00-17:00</p>

                    </div>
                </div>
                <div className='row' style={{transform:'skew(20deg)',margin:'1vw'}}>
                    <Phone svgStyle={{width:'1.5vw',fill:'#ff2c02',margin:'0 2vw 0 0'}} />
                        <p style={{fontSize:'1.4vw',margin:'0'}}>+39 351 162 2506</p>
                </div>
                <div className='row' style={{transform:'skew(20deg)',margin:'1vw'}}>
                    <Mail svgStyle={{width:'1.5vw',fill:'#ff2c02',margin:'0 2vw 0 0'}} />
                        <p style={{fontSize:'1.4vw',margin:'0'}}>info@rs-motors.eu</p>
                </div>

            </div>
        </div>
            
  
    
  )
}

export default Desktop;

