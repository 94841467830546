import React from 'react';

const pointServices_mob = props => {
  return (
    <div className='column relative' style={{width:'80vw',margin:'0 0 10vw 0',alignItems:'flex-start'}}>
    {   
        props.serviceNames.map(service=>{
           return(
           <div key={service} className='row'>
                    <div style={{width:'3vw',height:'3vw',background:'#ff2c02',transform:'skewX(-20deg)'}}></div>
                    <p style={{textAlign:'left',color:'#d9d9d9',fontSize:'5vw',margin:'3vw 0 3vw 5vw'}}>{service}</p>
                </div>
           );
        })
        
    }
</div>
  )
}

export default pointServices_mob;