import React from 'react';
import {whatsapp as Whats} from './icons';

const customWhats =({svgStyle,pathStyle,text,pStyle,parHeight,mainMargin})=> {
  return (
    <div className='row' style={{transform:'skewX(-20deg)',margin:mainMargin}}>
      <div className='row'>
        <div style={{width:'3vw',height:parHeight,transform:'skew(-20deg)',background:'#ff2c02'}}></div>
        <div style={{margin:'0 1vw',width:'1vw',height:parHeight,transform:'skew(-20deg)',background:'#ff2c02',opacity:'0.7'}}></div>
        <div style={{width:'0.5vw',height:parHeight,transform:'skew(-20deg)',background:'#ff2c02',opacity:'0.5'}}></div>
      </div>
      <a href="https://wa.me/+393511622506" target="blank" noopener='true' noreferrer='true' style={{textDecoration:'none',color:'#d9d9d9'}}>
      <div className='row' style={{transform:'skew(20deg)'}}>
        <Whats svgStyle={svgStyle} pathStyle={pathStyle} />
        <p style={pStyle}>{text}</p>
      </div>
      </a>
      <div className='row'>
        <div style={{width:'0.5vw',height:parHeight,transform:'skew(-20deg)',background:'#d9d9d9',opacity:'0.5'}}></div>
        <div style={{margin:'0 1vw',width:'1vw',height:parHeight,transform:'skew(-20deg)',background:'#d9d9d9',opacity:'0.7'}}></div>
        <div style={{width:'3vw',height:parHeight,transform:'skew(-20deg)',background:'#d9d9d9'}}></div>
      </div>
    </div>
  )
}

export default customWhats;