import React from 'react';
import Description from '../../functional/deskription';

import Header from '../../functional/deskHeader';
import Services from '../../functional/pointServices_desk';
import Whats from '../../functional/customWhats';
const Desktop =()=> {
  
  
  return (
    
        <div className='relative column' style={{width:'100vw',color:'#d9d9d9',overflowX:'hidden',overflowY:'scroll'}}>
            
            <Header text={'VENDITA SUPERCARS'} />
            <video style={{width:'100vw',height:'40vw',objectFit:'cover',margin:'0'}}
                   crossOrigin="*"
                   autoPlay
                   muted
                   loop>
                    <source src='https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/supercars_ferrari.mp4' type="video/mp4" />

            </video>
            <Description 
               w={'40vw'}
               align={'center'}
               parHeight={'1.5vw'}
               title={null}
               text={`Assistenza specializzata a 360° pre e post-vendita.`}
            />
            <Services serviceNames={[
              'finanziamenti e leasing',
              'garanzia di conformità',
              'permuta usato',
              'assistenza pre post vendita',
              'consegne auto',
              'conto vendita']}  />

            <Whats svgStyle={{width:'3.5vw',fill:'#25d366',margin:'0 1vw 0 1.5vw'}} 
             text={'RICHIEDI INFORMAZIONI'}
             pStyle={{fontSize:'1.3vw',fontWeight:'700',margin:'0 1.5vw 0 0'}}
             parHeight={'1.3vw'}
             mainMargin={'3vw 0 4vw 0'} />
            <iframe className='relative' src="https://www.autoscout24.it/concessionari/embedded-list/rs-motors-srl-roma?preview=false" scrolling="auto" frameBorder="0" width="100%" height="1024">Il tuo browser non supporta gli iframe.</iframe>
        </div>
            
  
    
  )
}

export default Desktop;

