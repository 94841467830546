import React from 'react';

import Carousel from '../../carousel/carousel';
import Description from '../../functional/deskription';
import FullDoubleImage from '../../functional/fullDoubleImage';
import zero from '../../../assets/about_0.jpg';
import one from '../../../assets/about_1.jpg';
import two from '../../../assets/about_2.jpg';
import Scout from '../../svg/autoScout';
import DSC from '../../svg/DSC';

const Desktop =()=> {
  
  
  return (
    
        <div className='relative column' style={{width:'100vw',color:'#d9d9d9',overflowX:'hidden'}}>
            
            <Carousel />
        
            <Description 
             w={'48vw'}
             align={'jusify'}
             parHeight={'4vw'}
             title={'RS '}
             text={`MOTORS nasce dalla passione per il mondo delle quattro ruote e un’ esperienza decennale nel settore.
             "Non solo auto ma tanto altro."`}
             />
             <FullDoubleImage 
          imageUrl={zero} 
          imgStyle={{height:'25vw',transform:'skew(20deg) translate(5.5vw,0)'}}
          image2Url={one} 
          img2Style={{height:'25vw',transform:'skew(20deg) translateX(0)'}}
          image3Url={two} 
          img3Style={{height:'25vw',transform:'skew(20deg) translate(-5vw,0)'}}
      />
      <div className='column'>
        <p style={{fontSize:'1.2vw',fontWeight:'300'}}>IN COLLABORAZIONE CON:</p>
        <div className='row'>
          <div className='row' style={{border:'1px solid #d9d9d9',margin:'0 1vw 0 0',transform:'skewX(-20deg)',padding:'1vw'}}>
            <DSC svgStyle={{height:'5vw',transform:'skewX(20deg)'}} />
          </div>
          <div className='row' style={{border:'1px solid #d9d9d9',transform:'skewX(-20deg)',padding:'1vw 2vw'}}>
          <Scout svgStyle={{height:'5vw',transform:'skewX(20deg)'}} />
          </div>
        </div>
      </div>
        
        </div>
            
  
    
  )
}

export default Desktop;

