import React from 'react';
import Rs from '../svg/rs';

const deskHeader = props => {
  return (
    <div className='row' style={{width:'100vw',margin:'7vw 0 2vw 0',height:'auto',alignItems:'baseline',justifyContent:'flex-start'}}>
        <div className='row relative' style={{alignItems:'baseline',margin:'0 0 0 5vw',transform:'skewX(0)'}}>
            <Rs svgStyle={{width:'15vw',fill:'#ff2c02',zIndex:'2'}} />
            <h1 className='mobH1 relative' style={{left:'-6vw'}}><span style={{color:'#000'}}>RS</span>MOTORS</h1>
        </div>
        <div className='column' style={{alignItems:'flex-start'}}>
          <h2 style={{margin:'0',fontSize:'5vw'}}>{props.text}</h2>
          <div style={{background:'#ff2c02',border:'1px solid #ff2c02',transform:'skew(-20deg)',width:'100vw',height:'1px'}}></div>
        </div>
        
       
    </div>
  )
}

export default deskHeader