import React from 'react';
import { NavLink } from 'react-router-dom';
const routes = [
    { path: '/', name: 'HOME'},
    { path: '/vendita',name: 'VENDITA'},
    { path: '/noleggio',name: 'NOLEGGIO SUPERCARS'},
    { path: '/simulazione',name: 'SIMULAZIONE'},
    { path: '/wrapping', name: 'WRAPPING'},
    { path: '/contatti',name: 'CONTATTI'},
  ]
const deskNav = props => {
  return (
    <div className='relative row' style={props.containerStyle}>
      {routes.map((route) => (
            <NavLink
              key={route.path}
              to={route.path}
              className={({ isActive }) => (isActive ? 'active' : 'unactive')}
              style={props.navStyle}
              end
            >
              {route.name}
            </NavLink>
          ))}
      </div>
  )
}

export default deskNav;