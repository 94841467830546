import React from 'react'

const rs_logo = props => {
  return (
    <svg
   xmlns="http://www.w3.org/2000/svg"
   style={props.svgStyle}
   version="1.1"
   viewBox="0 0 194.98 42.223">
  
  <path
     d="m 88.12475,37.100233 5.74912,-13.068192 h 4.953088 v 0.840256 q -0.707584,0.02211 -1.149824,0.176896 -0.420128,0.154784 -0.66336,0.530688 -0.22112,0.353792 -0.309568,0.99504 -0.06634,0.641248 -0.06634,1.636288 v 8.667904 q 0,1.1056 0.08845,1.746848 0.08845,0.641248 0.33168,0.99504 0.265344,0.353792 0.729696,0.464352 0.486464,0.11056 1.260384,0.132672 v 0.840256 H 91.88379 v -0.840256 q 0.77392,-0.02211 1.238272,-0.132672 0.486464,-0.132672 0.751808,-0.464352 0.265344,-0.33168 0.353792,-0.928704 0.08845,-0.619136 0.08845,-1.6584 V 26.773929 H 94.09499 L 87.638286,41.279401 H 87.151822 L 80.805678,26.773929 h -0.08845 v 10.105184 q 0,1.1056 0.08845,1.746848 0.08845,0.641248 0.33168,0.99504 0.265344,0.353792 0.729696,0.464352 0.486464,0.11056 1.260384,0.132672 v 0.840256 H 76.95819 v -0.840256 q 0.77392,-0.02211 1.238272,-0.132672 0.486464,-0.132672 0.751808,-0.464352 0.265344,-0.33168 0.353792,-0.928704 0.08845,-0.619136 0.08845,-1.6584 v -8.977472 q 0,-0.928704 -0.08845,-1.525728 -0.06634,-0.619136 -0.309568,-0.972928 -0.22112,-0.353792 -0.66336,-0.486464 -0.44224,-0.154784 -1.149824,-0.199008 v -0.840256 h 5.284768 l 5.572224,13.068192 z"
     style={{fill:'#d9d9d9'}}
    />
  <path
     d="m 111.40304,23.810921 q 1.94585,0 3.62637,0.685472 1.68051,0.685472 2.91878,1.87952 1.23827,1.194048 1.94586,2.808224 0.70758,1.614176 0.70758,3.493696 0,1.791072 -0.7297,3.361024 -0.70758,1.569952 -1.94585,2.741888 -1.23827,1.149824 -2.91879,1.835296 -1.68051,0.66336 -3.60425,0.66336 -1.92375,0 -3.60426,-0.66336 -1.68051,-0.685472 -2.91878,-1.835296 -1.23827,-1.171936 -1.96797,-2.741888 -0.70758,-1.569952 -0.70758,-3.361024 0,-1.87952 0.70758,-3.493696 0.70759,-1.614176 1.94586,-2.808224 1.23827,-1.194048 2.91878,-1.87952 1.68051,-0.685472 3.62637,-0.685472 z m 0,16.25232 q 1.45939,0 2.60921,-0.508576 1.17194,-0.508576 1.99008,-1.459392 0.84026,-0.950816 1.2825,-2.299648 0.44224,-1.370944 0.44224,-3.051456 0,-1.76896 -0.44224,-3.184128 -0.44224,-1.43728 -1.26038,-2.43232 -0.81815,-1.017152 -1.99008,-1.54784 -1.17194,-0.5528 -2.63133,-0.5528 -1.45939,0 -2.63133,0.5528 -1.17193,0.530688 -1.99008,1.54784 -0.81814,0.99504 -1.26038,2.43232 -0.44224,1.415168 -0.44224,3.184128 0,1.680512 0.44224,3.051456 0.44224,1.348832 1.26038,2.299648 0.81815,0.950816 1.99008,1.459392 1.17194,0.508576 2.63133,0.508576 z"
     style={{fill:'#d9d9d9'}}
    />
  <path
     d="m 140.19551,28.255433 h -0.90659 q -0.0663,-0.950816 -0.30957,-1.592064 -0.24323,-0.641248 -0.70759,-0.99504 -0.44224,-0.375904 -1.12771,-0.530688 -0.68547,-0.154784 -1.63629,-0.154784 -0.81814,0 -1.32672,0.04422 -0.48646,0.02211 -0.7518,0.11056 -0.26535,0.08845 -0.3538,0.22112 -0.0663,0.11056 -0.0663,0.309568 v 11.82992 q 0,0.707584 0.0884,1.21616 0.11056,0.508576 0.39801,0.840256 0.30957,0.309568 0.86237,0.486464 0.57491,0.154784 1.50362,0.176896 v 0.840256 h -8.35834 v -0.840256 q 0.9287,-0.02211 1.50362,-0.176896 0.57491,-0.176896 0.86236,-0.486464 0.30957,-0.33168 0.39802,-0.840256 0.11056,-0.508576 0.11056,-1.21616 v -11.82992 q 0,-0.199008 -0.0884,-0.309568 -0.0663,-0.132672 -0.30957,-0.22112 -0.22112,-0.08845 -0.64124,-0.11056 -0.42013,-0.04422 -1.12772,-0.04422 -0.86236,0 -1.61417,0.06634 -0.7297,0.06634 -1.2825,0.398016 -0.53069,0.309568 -0.86237,0.972928 -0.33168,0.66336 -0.35379,1.835296 h -0.81814 v -4.223392 h 16.69456 z"
     style={{fill:'#d9d9d9'}}
    />
  <path
     d="m 151.99317,23.810921 q 1.94586,0 3.62637,0.685472 1.68051,0.685472 2.91878,1.87952 1.23827,1.194048 1.94586,2.808224 0.70758,1.614176 0.70758,3.493696 0,1.791072 -0.72969,3.361024 -0.70759,1.569952 -1.94586,2.741888 -1.23827,1.149824 -2.91878,1.835296 -1.68052,0.66336 -3.60426,0.66336 -1.92374,0 -3.60426,-0.66336 -1.68051,-0.685472 -2.91878,-1.835296 -1.23827,-1.171936 -1.96797,-2.741888 -0.70758,-1.569952 -0.70758,-3.361024 0,-1.87952 0.70758,-3.493696 0.70759,-1.614176 1.94586,-2.808224 1.23827,-1.194048 2.91878,-1.87952 1.68051,-0.685472 3.62637,-0.685472 z m 0,16.25232 q 1.45939,0 2.60922,-0.508576 1.17193,-0.508576 1.99008,-1.459392 0.84025,-0.950816 1.28249,-2.299648 0.44224,-1.370944 0.44224,-3.051456 0,-1.76896 -0.44224,-3.184128 -0.44224,-1.43728 -1.26038,-2.43232 -0.81815,-1.017152 -1.99008,-1.54784 -1.17194,-0.5528 -2.63133,-0.5528 -1.45939,0 -2.63133,0.5528 -1.17193,0.530688 -1.99008,1.54784 -0.81814,0.99504 -1.26038,2.43232 -0.44224,1.415168 -0.44224,3.184128 0,1.680512 0.44224,3.051456 0.44224,1.348832 1.26038,2.299648 0.81815,0.950816 1.99008,1.459392 1.17194,0.508576 2.63133,0.508576 z"
     style={{fill:'#d9d9d9'}}
    />
  <path
     d="m 177.86893,28.299657 q 0,1.76896 -1.06137,2.940896 -1.06138,1.171936 -3.38314,1.569952 l 4.88675,5.881792 q 0.59703,0.729696 1.30461,1.061376 0.7297,0.309568 1.72474,0.464352 v 0.840256 h -4.26762 l -5.41744,-6.943168 q -0.28746,-0.375904 -0.5528,-0.597024 -0.24323,-0.22112 -0.53069,-0.33168 -0.26534,-0.132672 -0.64125,-0.154784 -0.35379,-0.04422 -0.86236,-0.04422 v 4.599296 q 0,0.818144 0.0884,1.348832 0.11056,0.508576 0.42013,0.796032 0.33168,0.287456 0.92871,0.398016 0.61913,0.08845 1.61417,0.08845 v 0.840256 h -7.80553 v -0.840256 q 0.77392,-0.02211 1.23827,-0.132672 0.48646,-0.11056 0.75181,-0.375904 0.26534,-0.265344 0.35379,-0.751808 0.0884,-0.486464 0.0884,-1.260384 V 27.393065 q 0,-0.77392 -0.0884,-1.238272 -0.0663,-0.486464 -0.30957,-0.751808 -0.22112,-0.287456 -0.66336,-0.375904 -0.44224,-0.11056 -1.14982,-0.154784 v -0.840256 h 6.94316 q 3.05146,0 4.70986,1.127712 1.68051,1.127712 1.68051,3.139904 z m -8.80057,3.582144 h 1.56995 q 2.2112,0 3.27257,-0.796032 1.08349,-0.818144 1.08349,-2.675552 0,-1.791072 -0.86237,-2.564992 -0.84025,-0.77392 -2.65344,-0.77392 h -2.0343 q -0.3759,0 -0.3759,0.375904 z"
     style={{fill:'#d9d9d9'}}
    />
  <path
     d="m 183.19089,35.861961 h 0.81814 q 0.15479,0.99504 0.57492,1.791072 0.44224,0.796032 1.08348,1.348832 0.66336,0.5528 1.50362,0.862368 0.86237,0.287456 1.85741,0.287456 1.63629,0 2.5871,-0.77392 0.95082,-0.77392 0.95082,-2.10064 0,-0.5528 -0.19901,-1.017152 -0.1769,-0.464352 -0.61914,-0.88448 -0.44224,-0.44224 -1.19404,-0.862368 -0.7297,-0.420128 -1.85741,-0.906592 -1.37095,-0.597024 -2.29965,-1.149824 -0.9287,-0.5528 -1.4815,-1.149824 -0.5528,-0.597024 -0.79604,-1.260384 -0.22112,-0.685472 -0.22112,-1.54784 0,-1.017152 0.3538,-1.87952 0.3759,-0.862368 1.03926,-1.481504 0.66336,-0.641248 1.56995,-0.972928 0.90659,-0.353792 1.99008,-0.353792 0.97293,0 1.81319,0.309568 0.84025,0.309568 1.70262,0.99504 l 0.57491,-0.99504 h 0.50858 l 0.24323,4.400288 h -0.81814 q -0.53069,-1.813184 -1.54784,-2.697664 -0.99504,-0.88448 -2.41021,-0.88448 -1.34883,0 -2.14487,0.66336 -0.79603,0.66336 -0.79603,1.791072 0,0.530688 0.1769,0.972928 0.17689,0.420128 0.57491,0.818144 0.39802,0.375904 1.03926,0.77392 0.64125,0.375904 1.59207,0.796032 1.63629,0.729696 2.71977,1.370944 1.08349,0.619136 1.72474,1.260384 0.64125,0.619136 0.90659,1.32672 0.26535,0.685472 0.26535,1.54784 0,1.1056 -0.44224,2.034304 -0.44224,0.928704 -1.23828,1.592064 -0.79603,0.66336 -1.90163,1.039264 -1.1056,0.353792 -2.43232,0.353792 -1.26038,0 -2.27753,-0.353792 -0.99504,-0.353792 -2.03431,-1.194048 l -0.61913,1.083488 h -0.50858 z"
     style={{fill:'#d9d9d9'}}
    />
  <path
     d="m 8.3912,10.85 q 0,4.2758 2.5654,7.1086 2.5654,2.8327 8.1772,3.7948 l -11.812,14.217 q -1.443,1.7638 -3.1533,2.5655 -1.7637,0.74828 -4.1688,1.1224 v 2.0311 h 10.315 l 13.094,-16.783 q 0.6948,-0.90861 1.3362,-1.4431 0.5879,-0.53444 1.2827,-0.80166 0.64136,-0.32079 1.5499,-0.37423 0.85514,-0.10689 2.0844,-0.10689 v 11.117 q 0,1.9776 -0.21378,3.2603 -0.26723,1.2293 -1.0155,1.9242 -0.80169,0.69484 -2.2447,0.96206 -1.4965,0.21378 -3.9016,0.21378 v 2.0311 h 18.866 v -2.0311 q -1.8706,-0.05338 -2.993,-0.32066 -1.1758,-0.26722 -1.8172,-0.90861 -0.64135,-0.64139 -0.85514,-1.8173 -0.21378,-1.1758 -0.21378,-3.0464 v -24.907 q 0,-1.8707 0.21378,-2.9931 0.16034,-1.1759 0.74824,-1.8172 0.53447,-0.69484 1.6034,-0.90861 1.0689,-0.26722 2.7792,-0.37411 v -2.0311 h -16.782 q -7.3755,0 -11.384,2.7258 -4.0619,2.7259 -4.0619,7.5896 z m 21.272,8.6586 h -3.7947 q -5.3446,0 -7.91,-1.9242 -2.6189,-1.9775 -2.6189,-6.4672 0,-4.3293 2.0844,-6.1999 2.031,-1.8707 6.4135,-1.8707 h 4.917 q 0.90858,0 0.90858,0.90861 z"
     style={{fill:'#ff2c02'}}
    />
  <path
     d="m 44.3422,29.129 h 1.9775 q 0.37412,2.4052 1.3896,4.3293 1.0689,1.9241 2.6189,3.2603 1.6034,1.3362 3.6343,2.0844 2.0844,0.69484 4.4895,0.69484 3.955,0 6.2532,-1.8707 2.2982,-1.8707 2.2982,-5.0776 0,-1.3362 -0.48101,-2.4586 -0.42757,-1.1224 -1.4965,-2.1379 -1.0689,-1.0689 -2.8861,-2.0845 -1.7637,-1.0154 -4.4895,-2.1913 -3.3137,-1.4432 -5.5584,-2.7793 -2.2447,-1.3362 -3.5809,-2.7793 -1.3362,-1.4431 -1.9241,-3.0466 -0.53447,-1.6568 -0.53447,-3.7413 0,-2.4586 0.85514,-4.5431 0.90858,-2.0844 2.512,-3.581 1.6034,-1.5499 3.7947,-2.3517 2.1913,-0.85511 4.8101,-0.85511 2.3516,0 4.3826,0.74822 2.0309,0.74834 4.1153,2.4052 l 1.3896,-2.4052 h 1.2292 l 0.58791,10.636 h -1.9775 q -1.2827,-4.3827 -3.7412,-6.5206 -2.4051,-2.1379 -5.8256,-2.1379 -3.2602,0 -5.1843,1.6034 -1.9241,1.6035 -1.9241,4.3293 0,1.2827 0.42757,2.3517 0.42757,1.0156 1.3896,1.9776 0.96203,0.90855 2.512,1.8706 1.5499,0.90867 3.8481,1.9242 3.955,1.7637 6.5739,3.3138 2.6189,1.4965 4.1688,3.0465 1.5499,1.4965 2.1913,3.2069 0.64135,1.6568 0.64135,3.7413 0,2.6723 -1.0689,4.9172 -1.0689,2.2448 -2.993,3.8482 -1.9241,1.6034 -4.5964,2.5121 -2.6723,0.85517 -5.8791,0.85517 -3.0464,0 -5.5049,-0.85517 -2.4051,-0.85517 -4.917,-2.8862 l -1.4965,2.6189 h -1.2293 z"
     style={{fill:'#ff2c02'}}
    />
</svg>
  )
}

export default rs_logo;