import React from 'react';


const deskription = props => {
  return (
    <div className='column relative' style={{width:'100vw',margin:'6vw 0 0 3vw'}}>
        <p style={{width:props.w,color:'#d9d9d9',textAlign:props.align,fontSize:'5vw',margin:props.margin,lineHeight:'6vw',padding:'3vw 0'}}><span style={{color:'#ff2c02',fontWeight:'700'}}>{props.title}</span>{props.text}</p>
    </div>
  )
}

export default deskription;