import React from 'react';

const fullImage =({imageUrl,image2Url,imgStyle,img2Style,alText,alText1})=> {
  return (
    <div className='column' style={{margin:'15vw 0'}}>
    <div className='row' style={{width:'100vw',height:'60vw',transform:'skewY(-20deg)',overflow:'hidden',margin:'0'}}>
      <img src={imageUrl} style={imgStyle} alt={alText} />
    </div>
    <div className='row' style={{width:'100vw',height:'60vw',transform:'skewY(-20deg)',overflow:'hidden',margin:'10vw 0'}}>
      <img src={image2Url} style={img2Style} alt={alText1} />
    </div>

  </div>
  )
}

export default fullImage;