import React from 'react';
import Logo from '../../svg/rs_logo';
import C0R3 from '../c0r3';
import {instagramIcon as Insta} from '../icons';

const pStyle = {fontSize:'4vw',color:'#d9d9d9',margin:'0'}
function Mobile() {
  return (
    <div className='column relative' style={{width:'90vw',margin:'10vw 0',borderTop:'1px solid rgb(50,50,50)',alignItems:'flex-start'}}>

      
<div className='row' style={{width:'85vw',justifyContent:'space-between'}}>

      <div className='column' style={{alignItems:'flex-start',margin:'10vw 0'}}>

          <Logo svgStyle={{width:'35vw'}}  />

            <p style={pStyle}>Piazza di Pietra 26, 00187 Roma</p>
            <p style={pStyle}>p.iva 16373931001</p>

    
      </div>

      <div className='row' style={{border:'1px solid #d9d9d9',transform:'skew(-20deg)',height:'10vw',width:'10vw',padding:'2vw'}} >
              <div style={{transform:'skew(20deg)'}}>
              <a  href='https://www.instagram.com/rsmotorsroma/' >
                <Insta svgStyle={{width:'7vw',fill:'#ff2c02dd'}} />
              </a>
              </div>

        </div>
            
</div>            
    
        


        <div className='column' >
         
                <p style={{fontSize:'3vw',margin:'0 0 0.5vw 0',color:'#d9d9d9',letterSpacing:'0.3vw'}}>DEVELOPED BY</p>
                <C0R3 svgStyle={{height:'5vw',fill:'#ff2c02'}} />
    
                
        </div>
      
      
        
        
    </div>
  )
}

export default Mobile