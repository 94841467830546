import React from 'react';
import Logo from '../../svg/rs_logo';
import C0R3 from '../c0r3';
import Nav from '../nav';
import {instagramIcon as Insta} from '../icons';

const pStyle = {fontSize:'1.1vw',color:'#d9d9d9',margin:'0'}

const deskFooter =()=> {
  return (
    <div className='column' style={{width:'90vw',margin:'5vw 0 0 0',padding:'2.5vw 0',borderTop:'1px solid rgb(50,50,50)'}}>

      
        <div className='row' style={{width:'90vw',justifyContent:'space-between',alignItems:'flex-end'}}>
            <div className='column' style={{alignItems:'flex-start'}}>
                <Logo svgStyle={{width:'8vw'}}  />
                <p style={{...pStyle,margin:'0.5vw 0 0.3vw 0'}}>Piazza di Pietra 26, 00187 Roma</p>
                <p style={pStyle}>p.iva 16373931001</p>
            </div>
            
              
             <Nav containerStyle={{width:'auto'}} navStyle={{fontSize:'1vw',margin:'0 2vw 0 0'}} />
             <div style={{border:'1px solid #d9d9d9',transform:'skew(-20deg)',padding:'0.8vw 1vw'}} >
              <div style={{transform:'skew(20deg)'}}>
              <a  href='https://www.instagram.com/rsmotorsroma/' >
                <Insta svgStyle={{width:'1.5vw',fill:'#ff2c02dd'}} />
              </a>
              </div>
             
            </div>
            
        
            <div className='column' style={{margin:'0 0 0 3vw',border:'1px solid #d9d9d9',transform:'skew(-20deg)',padding:'0.8vw 1vw'}}>
                    <p style={{fontSize:'0.8vw',margin:'0 0 0.5vw 0',color:'#d9d9d9',transform:'skew(20deg)'}}>DEVELOPED BY</p>
                    <C0R3 svgStyle={{width:'5vw',fill:'#ff2c02',transform:'skew(20deg)'}} />
            </div>
        </div>
        
        
    </div>
  )
}
export default deskFooter;