import React, {useEffect,useState} from 'react';
import {NavLink} from 'react-router-dom'
import RS from '../../svg/rs_logo';
import s2 from '../../../assets/s2.jpg';
import sc from '../../../assets/simul.jpg';
import Header from '../../functional/deskHeader';
import Description from '../../functional/deskription';
import FullDoubleImage from '../../functional/fullDoubleImage';
import driving from '../../../assets/drivingSimulationCenter.svg';
import Whats from '../../functional/customWhats';

const Desktop =()=> {
  
  
  return (
    
        <div className='relative column' style={{width:'90vw',color:'#d9d9d9'}}>
            <Header text={'DRIVING SIMULATOR'}/>
            <video style={{width:'100vw',height:'40vw',objectFit:'cover',margin:'0'}}
                   crossOrigin="*"
                   autoPlay
                   muted
                   loop>
                    <source src='https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/simulatore720px.mp4' type="video/mp4" />

            </video>
            <Description 
             w={'58vw'}
             parHeight={'13.5vw'}
             align={'justify'}
             title={'Impugnare il volante della Ferrari di Formula 1 è un sogno impossibile?'}
             text={` Con il nostro T7 Pro Simulatore Dinamico sarà quasi diventato Realtà! RSimulation è un servizio dedicato agli appassionati della guida virtuale, a chi sogna l’automobilismo da competizione, ai piloti che vogliono allenarsi in modo innovativo, ed a chi vuole semplicemente divertirsi e provare l' emozione di guidare come fosse un vero pilota!`}
             />

            <Whats svgStyle={{width:'3.5vw',fill:'#25d366',margin:'0 1vw 0 1.5vw'}} 
             text={'RICHIEDI INFORMAZIONI'}
             pStyle={{fontSize:'1.3vw',fontWeight:'700',margin:'0 1.5vw 0 0'}}
             parHeight={'1.3vw'}
             mainMargin={'4vw 0 1vw 0'} />

            <FullDoubleImage 
                imageUrl={'https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/volanteSimulatore.jpg'} 
                imgStyle={{width:'50vw',height:'25vw',objectFit:'cover',transform:'skew(20deg) translate(3vw,0)'}}
                image2Url={sc} 
                img2Style={{height:'25vw',objectFit:'cover',transform:'skew(20deg) translateX(-5vw)'}}
                image3Url={s2} 
                img3Style={{height:'25vw',objectFit:'cover',transform:'skew(20deg) translate(-5vw,0)'}}
            />
            <div className='row' style={{justifyContent:'space-between',width:'70vw'}}>
                <div className='column' style={{alignItems:'flex-start'}}>
                    <p  style={{fontSize:'1.8vw',margin:'0 0 2vw 0'}}>Vieni a trovarci presso il <span style={{color:'#ff2c02'}}>Driving Simulation Center</span> di Vallelunga</p>
                    <p  style={{fontSize:'1.5vw',margin:'0',alignSelf:'flex-end'}}>Dal Martedi al Venerdi / 19:00 - 23:30 </p>
                    <p  style={{fontSize:'1.5vw',margin:'0',alignSelf:'flex-end'}}>Sabato e Domenica / 10:00 - 23:00</p>
                </div>
                <img src={driving} style={{width:'20vw'}} />
            </div>
        </div>
            
  
    
  )
}

export default Desktop;

/*
<RS svgStyle={{width:'25vw',margin:'3vw 0 1vw 0'}} />
                <h2 style={{fontFamily:'Libre Baskerville',fontWeight:'400',fontSize:'1.2vw',color:'#d9d9d9',margin:'0'}}>SUPERCARS &amp; MOTORSPORTS</h2>
                <p style={{fontFamily:'Draco',fontSize:'1.5vw',color:'#ff2c02',letterSpacing:'0.2vw',margin:'3vw 0'}}>sito in costruzione</p>


     const arr = [img0,img1,img2,img3,img4,img5,img6,img7]
  const [i, setI] = useState(0);
  useEffect(()=>{
    const interval = setInterval(() => {
        if (i<arr.length-1){
            setI(i+1);
        }else{
            setI(0);
        }
    }, 600);

    return ()=>{
        clearInterval(interval)
    }
  })
            <img className='midDev' style={{objectFit:'cover',filter:'grayscale(100%)',objectPosition:'50% 30%'}} src={arr[i]} alt={'glitch'}/>

*/