import React from 'react';
import { Link } from 'react-router-dom';
import {button as CustomButton} from '../../functional/buttons';
const deskInfo =({pngHeight,png,texts,title,link})=> {
  return (
    <div className='absolute column' style={{width:'36vw',padding:'0 4vw 0 0',height:'50vw',transform:'skew(-20deg)',background:'rgba(0,0,0,0.8)',top:'0',left:'-10vw',alignItems:'flex-end',justifyContent:'flex-start'}}>
        <img src={png} style={{height:pngHeight,margin:'3vw 0 3vw 3vw',transform:'skew(20deg)'}} />
        <p style={{width:'20vw',margin:'1vw 0',color:'#ff2c02',textAlign:'right', fontWeight:'700',padding:'1vw',fontSize:'1.8vw'}}>{title}</p>
        {
            texts.map(text=>{
                return<div  key={text} className='row' style={{alignItems:'baseline'}}>
                  <div style={{width:'1vw',height:'1vw',background:'#ff2c02'}}></div>
                  <p style={{width:'20vw',textAlign:'right',color:'#d9d9d9',fontSize:'1.2vw',margin:'1vw 0 1vw 1vw'}}>{text}</p>
                </div>
            })
        }
        <Link to={link}>
            <CustomButton wrapStyle={{transform:'skew(20deg)',margin:'2vw 2.5vw 0 0'}} 
                          text={'SCOPRI'} />
        </Link>
    </div>
  )
}

export default deskInfo;