import React from 'react';
import Rs from '../svg/rs';

const deskHeader = props => {
  return (
    <div className='row' style={{width:'90vw',justifyContent:'flex-start',alignItems:'baseline',overflow:'hidden'}}>
        <div className='row ' style={{alignItems:'baseline',transform:'skewX(0)',width:'auto'}}>
            <Rs svgStyle={{width:'6vw',fill:'#ff2c02',zIndex:'2'}} />
            <h1 className='deskH1 relative' style={{left:'-3vw',margin:'0'}}><span style={{color:'#000'}}>RS</span> MOTORS</h1>
        </div>
        <div className='bw' style={{width:'15vw',margin:'0 4vw 0 0'}}></div>
        <h2 style={{fontSize:'1.8vw',fontWeight:'100',justifySelf:'center',width:'fit-content'}}>{props.text}</h2>
        <div  style={{width:'30vw',margin:'0 0 0 4vw', border:'1px solid #ff2c02'}}></div>
      
    </div>
  )
}

export default deskHeader