import React from 'react';

const rs = props => {
  return (
    <svg style={props.svgStyle} version="1.1" viewBox="0 0 72.829 42.223" xmlns="http://www.w3.org/2000/svg">
 <g transform="translate(-32.448 -38.839)">
  <path d="m40.839 49.689q0 4.2758 2.5654 7.1086 2.5654 2.8327 8.1772 3.7948l-11.812 14.217q-1.443 1.7638-3.1533 2.5655-1.7637 0.74828-4.1688 1.1224v2.0311h10.315l13.094-16.783q0.6948-0.90861 1.3362-1.4431 0.5879-0.53444 1.2827-0.80166 0.64136-0.32079 1.5499-0.37423 0.85514-0.10689 2.0844-0.10689v11.117q0 1.9776-0.21378 3.2603-0.26723 1.2293-1.0155 1.9242-0.80169 0.69484-2.2447 0.96206-1.4965 0.21378-3.9016 0.21378v2.0311h18.866v-2.0311q-1.8706-0.05338-2.993-0.32066-1.1758-0.26722-1.8172-0.90861-0.64135-0.64139-0.85514-1.8173-0.21378-1.1758-0.21378-3.0464v-24.907q0-1.8707 0.21378-2.9931 0.16034-1.1759 0.74824-1.8172 0.53447-0.69484 1.6034-0.90861 1.0689-0.26722 2.7792-0.37411v-2.0311h-16.782q-7.3755 0-11.384 2.7258-4.0619 2.7259-4.0619 7.5896zm21.272 8.6586h-3.7947q-5.3446 0-7.91-1.9242-2.6189-1.9775-2.6189-6.4672 0-4.3293 2.0844-6.1999 2.031-1.8707 6.4135-1.8707h4.917q0.90858 0 0.90858 0.90861z" style={props.pathStyle}/>
  <path d="m76.79 67.968h1.9775q0.37412 2.4052 1.3896 4.3293 1.0689 1.9241 2.6189 3.2603 1.6034 1.3362 3.6343 2.0844 2.0844 0.69484 4.4895 0.69484 3.955 0 6.2532-1.8707t2.2982-5.0776q0-1.3362-0.48101-2.4586-0.42757-1.1224-1.4965-2.1379-1.0689-1.0689-2.8861-2.0845-1.7637-1.0154-4.4895-2.1913-3.3137-1.4432-5.5584-2.7793-2.2447-1.3362-3.5809-2.7793t-1.9241-3.0466q-0.53447-1.6568-0.53447-3.7413 0-2.4586 0.85514-4.5431 0.90858-2.0844 2.512-3.581 1.6034-1.5499 3.7947-2.3517 2.1913-0.85511 4.8101-0.85511 2.3516 0 4.3826 0.74822 2.0309 0.74834 4.1153 2.4052l1.3896-2.4052h1.2292l0.58791 10.636h-1.9775q-1.2827-4.3827-3.7412-6.5206-2.4051-2.1379-5.8256-2.1379-3.2602 0-5.1843 1.6034-1.9241 1.6035-1.9241 4.3293 0 1.2827 0.42757 2.3517 0.42757 1.0156 1.3896 1.9776 0.96203 0.90855 2.512 1.8706 1.5499 0.90867 3.8481 1.9242 3.955 1.7637 6.5739 3.3138 2.6189 1.4965 4.1688 3.0465 1.5499 1.4965 2.1913 3.2069 0.64135 1.6568 0.64135 3.7413 0 2.6723-1.0689 4.9172-1.0689 2.2448-2.993 3.8482t-4.5964 2.5121q-2.6723 0.85517-5.8791 0.85517-3.0464 0-5.5049-0.85517-2.4051-0.85517-4.917-2.8862l-1.4965 2.6189h-1.2293z" style={props.pathStyle}/>
 </g>
</svg>

  )
}

export default rs