import React from 'react';


const deskription = props => {
  return (
    <div className='column relative' style={{width:'100vw',margin:'6vw 0 10vw 3vw'}}>
        <p style={{width:props.w,color:'#ff2c02',textAlign:props.align,fontSize:'5.5vw',fontWeight:'700',margin:'5vw 0',lineHeight:'6vw'}}>{props.title}</p>
        <p style={{width:props.w,color:'#d9d9d9',textAlign:props.align,fontSize:'5vw',margin:props.margin,lineHeight:'6vw',padding:'0.5vw 0'}}>{props.text}</p>
        {
          props.text1?
            <p style={{width:props.w,color:'#d9d9d9',textAlign:props.align,fontSize:'5vw',margin:props.margin,lineHeight:'6vw',padding:'0.5vw 0'}}>{props.text1}</p>
          :
            null
        }
        {
          props.text2?
            <p style={{width:props.w,color:'#d9d9d9',textAlign:props.align,fontSize:'5vw',margin:props.margin,lineHeight:'6vw',padding:'0.5vw 0'}}>{props.text2}</p>
          :
            null
        }
    </div>
  )
}

export default deskription;