import React from 'react';
import { Link } from 'react-router-dom';
import {mobButton as CustomButton} from '../../functional/buttons';
const mobInfo =({pngHeight,png,texts,title,link})=> {
  return (
    <div className='absolute column' style={{width:'100vw',height:'60vw',transform:'skewY(20deg)',background:'rgba(0,0,0,0.8)',top:'80vw'}}>
        <div className='absolute column' style={{width:'90vw',height:'60vw',transform:'skewY(-20deg)',justifyContent:'flex-start',alignItems:'flex-start'}}>
            <div className='absolute row ' style={{top:'-75vw',right:'0'}}>
                <div className='absolute'
                     style={{background:'rgba(0,0,0,0.8)',width:'200vw',height:'50vw',transform:'skewY(20deg)'}}></div>
                <img className='absolute' src={png} style={{height:pngHeight,transform:'skewX(0) translate(-20vw,3vw)'}} />

            </div>
            <div className='row' style={{width:'78vw',justifyContent:'space-between',margin:'0 0 20vw 0'}}>
                <p style={{width:'50vw',minHeight:'17vw',color:'#ff2c02',textAlign:'left', fontWeight:'700',fontSize:'5vw',padding:'0',margin:'5vw 0 0 0'}}>{title}</p>
                <Link to={link}>
                    <CustomButton wrapStyle={{margin:'0'}} text={'SCOPRI'} />
                </Link>
             </div>
            
        
            
            <div className='column' style={{width:'90vw',justifyContent:'space-between'}}>
                
            <div className='row' style={{justifyContent:'space-between',width:'90vw'}}>
                <div style={{transform:'skewX(-20deg)',height:'10vw',width:'2vw',background:'#ff2c02'}}></div>
                <p style={{width:'82vw',textAlign:'justify',color:'#d9d9d9',fontSize:'4vw'}}>{texts[0].toUpperCase()}</p>

            </div>
            <div className='row' style={{justifyContent:'space-between',width:'90vw'}}>
            <div style={{transform:'skewX(-20deg)',height:'10vw',width:'2vw',background:'#ff2c02'}}></div>
                <p style={{width:'82vw',textAlign:'justify',color:'#d9d9d9',fontSize:'4vw'}}>{texts[1].toUpperCase()}</p>

            </div>

            </div>
            
            

        </div>
        
    </div>
  )
}

export default mobInfo;