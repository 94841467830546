import React,{useState, useEffect} from 'react';
import { CSSTransition } from 'react-transition-group';
import base from '../../../assets/rs_simulation0.png';
import rsrent from '../../../assets/rs_rent.png';
import rsupercars from '../../../assets/rs_supercars.png';
import rswrapping from '../../../assets/rs_wrapping.png';
import Item from './deskInfo';

const Desktop =()=> {
  const [item,setItem] = useState(0);

  
  useEffect(()=>{
    const interval = setTimeout(()=>{
        if(item<2){
            setItem((item+1));
            
            console.log('setted')
        }else{
            setItem(0);
            
            console.log('setted 0')
            }
        },15000)
        return ()=>{
            clearTimeout(interval);
        console.log('clear exit');
        }
    },[item])

  
  return (
    
        <div className='relative column' style={{width:'100vw',color:'#d9d9d9',overflow:'hidden',height:'50vw'}}>
            <CSSTransition 
                    in={(item===0)} 
                    
                    timeout={700} 
                    classNames="mainCarousel"
                    mountOnEnter
                    unmountOnExit>
                   <video style={{width:'100vw',height:'50vw',objectFit:'cover',margin:'0',position:'absolute'}}
                   crossOrigin="*"
                   autoPlay
                   muted
                   loop>
                    <source src='https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/supercars_ferrari.mp4' type="video/mp4" />

            </video>  
            </CSSTransition>
            <CSSTransition 
            
                    in={(item===0)} 
                    timeout={700} 
                    classNames="sideCarousel"
                    mountOnEnter
                    unmountOnExit>
                <div className='absolute' style={{top:'0',left:'0'}}>
                <Item
                pngHeight={'4vw'} 
                png={rsupercars}
                title={'COMPRAVENDITA VEICOLI ESCLUSIVI'}
                texts={
                    ['Vendita e acquisto di supercars e veicoli esclusivi',
                    'Import export, finanziamenti personalizzati, leasing, auto aziendali']
                    }
                link={'/vendita'}
                />
                </div>
            </CSSTransition>


            

            <CSSTransition 
                    in={(item===1)} 
                    
                    timeout={700} 
                    classNames="mainCarousel"
                    mountOnEnter
                    unmountOnExit>
                <video style={{width:'100vw',height:'50vw',objectFit:'cover',margin:'0',position:'absolute'}}
                   crossOrigin="*"
                   autoPlay
                   muted
                   loop>
                    <source src='https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/rent.mp4' type="video/mp4" />

                </video> 
            </CSSTransition>
            <CSSTransition 
                    in={(item===1)} 
                    
                    timeout={700} 
                    classNames="sideCarousel"
                    mountOnEnter
                    unmountOnExit>
                <div className='absolute' style={{top:'0',left:'0'}}>
                <Item
                pngHeight={'4vw'} 
                png={rsrent}
                title={'NOLEGGIO SUPERCARS'}
                texts={
                    ['Noleggiamo diversi tipi di auto, dalla supercar al SUV fino alla city car',
                    'Consegna su tutto il territorio nazionale 365 giorni l\'anno']
                    }
                link={'/noleggio'}
                />
                </div>
            </CSSTransition>
            <CSSTransition 
                    in={(item===2)} 
                    
                    timeout={700} 
                    classNames="mainCarousel"
                    mountOnEnter
                    unmountOnExit>
            <video style={{width:'100vw',height:'50vw',objectFit:'cover',margin:'0',position:'absolute'}}
                   crossOrigin="*"
                   autoPlay
                   muted
                   loop>
                    <source src='https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/simulatore720px.mp4' type="video/mp4" />

            </video>            
            </CSSTransition>
            <CSSTransition 
                    in={(item===2)} 
                    
                    timeout={700} 
                    classNames="sideCarousel"
                    mountOnEnter
                    unmountOnExit>
            <div className='absolute' style={{top:'0',left:'0'}}>
                <Item
                pngHeight={'5vw'} 
                png={base}
                title={'SIMULATORE T7 PRO'}
                texts={
                    ['Prima struttura in Europa aperta al pubblico con un simulatore avanzato a 7 assi di movimento',
                    'Ti aspettiamo al Driving Simulation Center di Vallelunga']
                    }
                    link={'/simulazione'}
                />
                </div>
            </CSSTransition>




     {/*<CSSTransition 
                    in={(item===3)} 
                    
                    timeout={700} 
                    classNames="mainCarousel"
                    mountOnEnter
                    unmountOnExit>
                <video style={{width:'100vw',height:'50vw',objectFit:'cover',margin:'0',position:'absolute'}}
                   crossOrigin="*"
                   autoPlay
                   muted
                   loop>
                    <source src='https://c0r3test.s3.eu-central-1.amazonaws.com/rs-motors/rs_wrapping_desk.mp4' type="video/mp4" />

            </video>  
            </CSSTransition>
            <CSSTransition 
                    in={(item===3)} 
                    
                    timeout={700} 
                    classNames="sideCarousel"
                    mountOnEnter
                    unmountOnExit>
                <div className='absolute' style={{top:'0',left:'0'}}>
                <Item
                pngHeight={'4vw'} 
                png={rswrapping}
                title={'CUSTOM WRAPPING'}
                texts={
                    ['Personalizza la tua vettura con pellicole all\' avanguardia e paint protection film',
                    'Oscuramento vetri e fanali, servizi per la manutenzione sia esterna che interna']
                    }
                link={'/wrapping'}
                />
                </div>
            </CSSTransition>*/}

            <div className='absolute column' style={{bottom:'5vw',left:'3vw'}}>
                <div className='row' style={{width:'18vw',justifyContent:'space-between'}} >
                    <div onClick={()=>{setItem(0)}} className='carouselPoints' style={{transform:'skew(-30deg)',transition:'700ms',width:'5.7vw',height:'0.25vw',background:item===0?'#ff2c02':'#d9d9d920'}}></div>
                    <div onClick={()=>{setItem(1)}} className='carouselPoints' style={{transform:'skew(-30deg)',transition:'700ms',width:'5.7vw',height:'0.25vw',background:item===1?'#ff2c02':'#d9d9d920'}}></div>
                    <div onClick={()=>{setItem(2)}} className='carouselPoints' style={{transform:'skew(-30deg)',transition:'700ms',width:'5.7vw',height:'0.25vw',background:item===2?'#ff2c02':'#d9d9d920'}}></div>
{/*                    <div onClick={()=>{setItem(3)}} className='carouselPoints' style={{transform:'skew(-30deg)',transition:'700ms',width:'4.3vw',height:'0.25vw',background:item===3?'#ff2c02':'#d9d9d920'}}></div> 
*/}                    
                </div>
                <div className='row' style={{width:'17.7vw',height:'5vw',bottom:'5vw',left:'3vw',justifyContent:'space-between',transform:'translateX(-0.7vw)',fontWeight:'800'}}>
                    <div className='row carouselBtn' onClick={()=>{item>0?setItem(item-1):setItem(2)}}>
                    prev
                    </div>
                    <div className='row carouselBtn' onClick={()=>{item<2?setItem(item+1):setItem(0)}}>
                    next
                    </div>
                </div>
            </div>
            
        </div>
            
  
    
  )
}

export default Desktop;

